<template>
    <div>
    <el-card>
        <div style="margin-bottom: 20px; dispaly:flex">
            <el-tag type="info">积分剩余总量：{{totalBonus}}</el-tag>
        <el-button type="primary" size="small" style="margin-left: 30px;"  @click="add"  v-show="$store.state.btn_if.indexOf('user_bonus_operate') != -1">{{
          '积分增减'
        }}</el-button>
        </div>
        <div>
    <nav class="input" style="width: auto">
        <el-input
            v-model="search.keyWord"
            size="medium"
            placeholder="用户ID，姓名"
          />
      </nav>
          <nav class="input" style="width: auto">
        <el-select v-model="search.type" placeholder="操作" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option v-for="(item, index) in newList" :key="index" :label="item.value" :value="item.key" />
          </el-select>
      </nav>
       <!-- <label
          for=""
          style="font-size: 14px; float: left; transform: translateY(9px)"
          >发布时间：</label
        > -->

        <nav class="input">
          <el-date-picker
            v-model="search.createDate"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 100%"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="medium"
          />
        </nav>
      <nav class="input" style="width: 150px">
          <el-button size="small" @click="reset">重置</el-button>
          <el-button type="primary" size="small" @click="subimt">{{
            '搜索'
          }}</el-button>
        </nav>
      </nav>
        <!-- <nav class="input" style="width: auto">
        <el-button type="primary" size="small"  @click="add">{{
          '添加商品'
        }}</el-button>
      </nav> -->
        </div>

    </el-card> 
    
        <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
      >
      <el-table-column label="ID" prop="id">
      </el-table-column>
    <el-table-column label="用户ID" prop="userId">
      </el-table-column>
    <el-table-column label="姓名" prop="userId">
        <template slot-scope="{row}">
            {{row.userRealName||row.userNickname}}
        </template>
      </el-table-column>
        <el-table-column label="操作" prop="typeName">
      </el-table-column>
      <el-table-column label="数量" prop="bonus">
          <template slot-scope="{row}">
              <span v-if="row.bonus>0">+</span>{{row.bonus}}
          </template>
      </el-table-column>
           <el-table-column label="时间" prop="createTime">
      </el-table-column>
        <!-- <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button
              type="text"
              icon="el-icon-delete"
              size="mini"
              class="red"
            >删除</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        :current-page="search.page"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="search.pageSize"
        layout="total, prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </el-card>

    <!-- 积分弹窗 start -->
    <popup ref="pop" title="积分增减" :id="types" @getlist="getlist"></popup>
    <!-- 积分弹窗 end -->
    </div>
</template>

<script>
import popup from '@/components/popup.vue';
    import list from '/src/mixin/list.js'
    export default {
    components: { popup },
        mixins: [list],
        data() {
            return {
                search: {
                    page:1,
                    pageSize:10,
                    type:'',
                    keyWord:'',
                    createDate:[]
                },
                totalBonus:'',
                first:true,
                newList:[],
                types:5
            }
        },
       async created () {
            this.getlist();

        },
        methods: {
            add() {
                this.$refs.pop.dialogVisible=true
                this.$set(this.$refs.pop.form,'endType',0)
            },
           async getlist(){
               if(this.first){
            var {data:gets} = await this.$http.get('/admin/Common/getConfigDictionaries?config=task')
            // console.log(gets);
            this.newList = gets.data.type
            this.first =false
               }

             var {data:res} = await  this.$http.get('/admin/User/getBonusLog',{params:{
                 ...this.search,
                 createDate:this.search.createDate&&this.search.createDate.join('~')
             }})
            //  console.log(res);
            this.list=res.data.list
            this.total =res.data.totalCount
            this.totalBonus=res.data.totalBonus
            },
            subimt(){
                this.search.page=1
                this.getlist()
            }
        },
    }
</script>

<style lang="less" scoped>

</style>